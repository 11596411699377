import React from "react";

function ErrorPage() {
  return (
    <div className="error-container">
        <div>ERROR! Page Not Found</div>
    </div>
    );
}

export default ErrorPage;