import React from "react";
import "./viewButton.scss"

function ViewButton() {

  return (
    <div className="view-button-wrapper">

    </div>
  );
}

export default ViewButton;
